
/*=============================================================================================================================================*/
/*================                                                                                                             ================*/
/*================                                                CSS du site client.                                          ================*/
/*================                                          Variables de customisation du theme                                ================*/
/*================                                                                                                             ================*/
/*=============================================================================================================================================*/

/* Table of content

$ACCESSIBILITY
$INTERNATIONALISATION
$IMAGES
$DEFAULT THEME CUSTOMISATION
$DEFAULT COLORS
$DEFAULT FONTS
$CHILD THEME
$EFFECTS
$COMPATIBILITY

*/


$copyright:                   false;// true => Active copyright

$language-bg: #fff; //$language-bg
$language-text: #38618b; //$language-text

$language-bg-hover: #fe9500; //$language-hover-bg-vertical
$language-text-hover: #fff; //$hoveractivelanguage-text

$mobile-language-bg: #fff; //$burger-lang-bg
$mobile-language-text: #38618b; //$burger-lang-text

$mobile-language-bg-hover: #fe9500; //$language-hover-mobile
$mobile-language-text-hover: #fff; //$hoverlanguage-text-mobile

$active-language-bg: #000; // ??
$active-language-text: #38618b; //$activelanguage-text

$active-language-bg-hover: #fe9500; //$language-hover-active-bg
$active-language-text-hover: #fff; //$hoveractivelanguage-text
/*=================================================================================*/
/*================                 $ACCESSIBILITY                  ================*/
/*=================================================================================*/

/* teste l'accessibilité en local avec CSS : entoure toutes les mauvaises pratiques */
/* laissez en commentaire pour la mise en prod !!! */
//@import "scss/a11y/a11y-fr";



/*=================================================================================*/
/*================                 $INTERNATIONALISATION           ================*/
/*=================================================================================*/

/* Un bien grand mot ! solution de secours pour les textes multilingues en dur dans master.html */
/* laissez en commentaire si pas besoin de ce patch */
@import "scss/l18n/l18n.scss";




/*======================================================================================*/
/*================           $DEFAULT THEME CUSTOMISATION               ================*/
/*======================================================================================*/



/*=================================================================================*/
/*================                 $COMPATIBILITY                  ================*/
/*=================================================================================*/

/* ajoute la compatibilité IOS6, IOS7 et IE10 */
/* laissez en commentaire si pas nécessaire : alourdit le code pour des très vieilles versions de navigateurs  */
//@import "../../../../../app/deployment_sources/common/evolutions/old-browsers"




// SASS _variables end
