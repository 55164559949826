/* Masquage CSS des éléments traduits en dur dans l'HTML */
/* Solution de secours ! */

.lang-fr-FR .en-GB,
.lang-fr-FR .it-IT,

.lang-en-GB .fr-FR,
.lang-en-GB .it-IT,

.lang-it-IT .fr-FR,
.lang-it-IT .en-GB {
  display: none;
}


.lang-fr-FR #header ul li a.en-GB,
.lang-fr-FR #header ul li a.it-IT,
.lang-en-GB #header ul li a.fr-FR,
.lang-en-GB #header ul li a.it-IT,
.lang-it-IT #header ul li a.fr-FR,
.lang-it-IT #header ul li a.en-GB {
  display: none; 
}