#module_mZst8mbUlk{
    margin-top: -291px;
}
/*Specific page hotel delete btn previous*/
#iLvZnt39q8 .extension_Tunnel .btn-previous{
    display:none;
}
/*===============================
=            GENERAL            =
===============================*/

body, #body {
    background-color: #F2F2F2;
    color: #333;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
}

#body {
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.15);
    margin: 50px;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}
h1 {
    color: #1F356F;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 30px;
    padding-bottom: 15px;
    text-transform: none;
}

#content #content-inter { 
    background-color: #FFF;

}
#content #content-inter .row{ 
    margin-left: 0px;
    margin-right: 0px;
}
.container-fluid .headerSites,
.container-fluid .headerBooking{
    padding-left: 0px;
}

.content .row .module{
    padding-right: 20px !important;
    padding-left: 20px !important;
}
.content .row{
    padding-top: 20px;
    padding-bottom: 20px;
}
.content .products .row{
    padding-bottom: 10px;
    padding-top: 10px;
}
.content .products .col-sm-12{
    padding-right: 0px;
    padding-left: 0px;
}

.extension_Adhesion .manageMembership-wrapper{
    margin-bottom: 15px !important;
    border: 1px solid #ddd;
    border-top: none !important;
    margin-top: -15px;
    background-color: #F2F2F2;
    
    
}
.extension_Adhesion .manageMembership-wrapper .manageMessage{
    padding:15px 0px;
    border: 1px solid #ddd;
    float: none !important;
    background-color: white;
}

.allMembers-wrapper.vertical .adhesion-infos.left.no-pass.col-md-6{
    margin-top: 0px !important;
}

.allMembers-wrapper.vertical .modifUser-wrapper.modif label{
    text-align: left;
}
.allMembers-wrapper.vertical .modifUser-wrapper.modif{
    text-align: center;
}
.allMembers-wrapper.vertical .calendarSpan{
    display: inline-block;
}
.allMembers-wrapper.vertical .user-infos .bottom-button.modif,
.allMembers-wrapper.vertical .user-infos .memberTooltip{
    display: inline-block;
}
.allMembers-wrapper.vertical .user-infos .memberTooltip{
    background-color: #EA700D;
    color: white;
    height: 35px;
    cursor: pointer;
    line-height: 35px;
    width: 40px;
    text-align: center;
    border-radius: 5px;
}
.allMembers-wrapper.vertical .user-infos .memberTooltip:hover{
    opacity: 0.8;
}


.allMembers-wrapper.vertical.no-picture .bottom-button.create.only{
    right: 244px;
}
.allMembers-wrapper.vertical .porteur .adhesion-infos.left.no-pass .bottom-button.create.noAdh{
    margin-top: 58px;
    right: 0px;
}
.allMembers-wrapper.vertical .adhesion-infos.left.no-pass .bottom-button.create.noAdh{
    margin-top: 97px;
    right: 0px;
}
.allMembers-wrapper.vertical .adhesion-wrapper.associes.noButton,
.allMembers-wrapper.vertical .adhesion-wrapper.noButton{
    padding: 30px 30px 10px !important;
}

.adhesion-infos.no-pass > span:first-child{
    font-weight: bold;
}
.allAdhWrapper.row
{
    padding-left: 0px !important;
}
.row.adhLibelle{
    padding-bottom: 0px !important;
}
.user-infos .userEmail{
    display: none;
}
.fa-stack {
    width: 1em !important;
}
/*-----  End of GENERAL  ------*/


/*==============================
=            HEADER            =
==============================*/
#header {
    background-color: #06202f;
    height: 60px;
    padding-top: 5px;
}
#header .container{
    padding-right: 0px !important;
    padding-left: 0px !important;
}
#header ul {
    margin: 0;
    overflow: auto;
    padding-top: 5px;
}
#header ul li {
    display: block;
    float: left;
}
#header ul li a {
    background: none no-repeat scroll center center #d5eefd;
    border-radius: 5px 5px 0 0  !important;
    color: #FFF;
    display: block;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    height: 50px;
    line-height: 50px;
    margin-right: 10px;
    padding: 0 10px;
    text-align: center;
    text-indent: -9999px;
    text-transform: uppercase;
    box-shadow: 0px 1px 0px 0px #FFBF65 inset, 0px -25px 25px -10px rgba(0, 0, 0, 0.4) inset;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.billeterie.active{
    background-color: #FFF;
    box-shadow: none;
    color: #2A576D;
    height: 43px;
    margin: 7px 10px 7px 0px;
    width: auto;
}
#header ul li.active a,
#header ul li a:hover {
    -webkit-box-shadow: 0 -7px 5px -5px #aaa inset;
    -moz-box-shadow: 0 -7px 5px -5px #aaa inset;
    box-shadow: 0 -7px 5px -5px #aaa inset;
    text-decoration: none;
}
#header ul li a.marineland,
#header ul li a.aquasplash,
#header ul li a.kidsisland,
#header ul li a.adventuregolf,
#header ul li a.lagoon{
    min-width: 118px;
    padding: 0;
}

#header ul li a.marineland {
    background-image: url("img/marineland-bleu-orange.jpg");
    background-size: 70%;
}
#header ul li a.aquasplash {
    background-image: url("img/Aquasplash_antibes.jpg");
    background-size: 70%;
}
#header ul li a.kidsisland {
    background-image: url("img/Kids-Island_1.jpg");
    background-size: 70%;
}
#header ul li a.adventuregolf {
    background-image: url("img/logo-adventuregolf.png");
}
#header ul li a.lagoon {
    background-image: url("img/logo-lagoon.png");
}
#header ul li a.billeterie,
#header ul li a.hotel {
    background-color: #fe9500;
    text-indent: 0;
    -moz-transition: height 0.5s, margin 0.5s, background-color linear 0.5s, color linear 0.5s;
    -o-transition: height 0.5s, margin 0.5s, background-color linear 0.5s, color linear 0.5s;
    -webkit-transition: height 0.5s, margin 0.5s, background-color linear 0.5s, color linear 0.5s;
    transition: height 0.5s, margin 0.5s, background-color linear 0.5s, color linear 0.5s;
}

#header ul li.active a.billeterie,
#header ul li a.billeterie:hover,
#header ul li a.billeterie:focus,
#header ul li.active a.hotel,
#header ul li a.hotel:hover,
#header ul li a.hotel:focus {
    background-color: #fff;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    color: #2a576d;
    height: 43px;
    margin: 7px 10px 7px 0;
    width: auto;
}
#header ul li a.hotel,
#header ul li a.hotel:hover,
#header ul li a.hotel:focus,
#header ul li.active a.hotel {
    margin-right: 0;
}
#header a i.icon-reorder {
    color: #FFF;
    cursor: pointer;
    font-size: 40px;
    line-height: 50px;
}
#header a:hover,
#header a:focus {
    text-decoration: none;
}
#header ul.list-menu {
    background-color: #D5EEFD;
    box-shadow: 5px 5px 5px #AAAAAA;
    display: block !important;
    padding: 0 15px;
    position: absolute;
    top: 55px;
    z-index: 25;
}
#header ul.list-menu li.active a,
#header ul.list-menu li a:hover {
    box-shadow: 0px 0px 5px 1px #AAAAAA inset;
}
#header ul.list-menu li {
    float: none;
    margin: 15px 0;
}
#header ul.list-menu li a {
    background-color: #FFF;
    background-image: none;
    border-radius: 0 !important;
    color: #555;
    margin: 0;
    text-indent: inherit;
}
/*-----  End of HEADER  ------*/



/*============================
=            MENU            =
============================*/
#menu {
    background-color: #FFF;
    padding: 10px 0;   
}
#menu .select2-container,
#menu .select2-choice {
    color: #38618b;
    font-size: 12px;
    font-weight: bold;
    height: 32px;
    line-height: 30px;
    text-transform: uppercase;
}

#menu .select2-choice {
    padding: 0 4px 4px 12px;
}

#menu .select2-choice span img.flag {
    padding-bottom: 2px;
}

.select2-drop {
    color: #38618b;
    font-size: 12px;
    font-weight: bold;
    margin-top: -3px;
    text-transform: uppercase;
}

.select2-results .select2-highlighted {
    color: #38618b;
}
.select2-container .select2-choice div b:before {
    line-height: 28px;
}
#menu nav ul {
    /*margin: 15px 0;*/
    overflow: auto;
}
#menu nav ul li ul {
    margin: 0;
}
#menu nav ul > li {
    display: block;
    float: left;
}
#menu nav ul > li > a {
    background-color: #fff;
    border: 1px solid #E5E5E5;
    border-radius: 5px !important;
    color: #38618b;
    display: block;
    font-size: 12px;
    font-weight: bold;
    margin-right: 10px;
    padding: 5px 15px;
    text-transform: uppercase;
    -moz-transition:    background-color 0.5s linear, border-color 0.5s linear, color 0.5s linear;
    -o-transition:      background-color 0.5s linear, border-color 0.5s linear, color 0.5s linear;
    -webkit-transition: background-color 0.5s linear, border-color 0.5s linear, color 0.5s linear;
    transition:         background-color 0.5s linear, border-color 0.5s linear, color 0.5s linear;
    white-space: nowrap;
}
#menu nav ul li a:hover,
#menu nav ul li a:focus,
#menu nav ul li.active > a {
    background-color: #fe9500;
    border-color: #fe9500;
    color: #fff;
    text-decoration: none;
}
#menu nav ul > li > ul {
    background-color: #fff;
    -webkit-box-shadow: 5px 5px 5px #aaa;
    -moz-box-shadow: 5px 5px 5px #aaa;
    box-shadow: 5px 5px 5px #aaa;
    display: none;
    position: absolute;
    z-index: 10;
}
#menu nav ul > li > ul > li {
    padding: 5px 15px;
}
#menu nav ul > li > ul > li > a {
    margin: 0;
}
#menu nav ul > li:hover > ul {
    display: block;
}
#menu nav ul > li > ul > li {
    float: none;
}
#menu nav ul > li > ul > li:hover > ul {
    display: block;
    left: 100%;
    top: 0;
}
/*-----  End of MENU  ------*/


/*================================
=            SESSION             =
================================*/
.extension_Sessions .row{
    padding-top:0px;
    padding-bottom:0px;
}

/*================================
=            LANGUAGE            =
================================*/

.extension_Language, .extension_Language div ul{
    margin:15px 0;
    width: 140px;
}
.extension_Language .languageModule li{
    padding: 0px 0px;
    padding-left: 5px;
}
.extension_Language .languageModule ul{
    padding-left: 7px;
    padding-right: 0px;
    width: 140px;
}
.extension_Language .activeLanguageDisplayer{
    padding: 0 22px;
    width: 140px;
}
.extension_Language div:hover div{
    background-color: #FE9500;border-bottom-left-radius:0px;
    border-bottom-right-radius:0px;
    color:white !important;
    transition:0.8s all;  
}
.extension_Language div div{
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    color:#38618B !important;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    z-index: 20;
}
.extension_Language div ul{
    width: inherit;
    background-color: white;
    border: 1px solid #ddd !important;
    display: none;
    list-style-type: none;
    margin: 0;
    z-index: 30;
}
.extension_Language div ul li:hover{
    opacity: 1 !important;
    
}
.extension_Language div ul li:hover a{
    color:#38618B !important;
    font-weight: bold;
    text-decoration: none !important;
    opacity: 1 !important;
}
.extension_Language div ul li,
.extension_Language div div{
    background-color: white;
    color:black;
    cursor: pointer;
    
}
.extension_Language div ul li a{
    color: black !important;
}
.extension_Language div ul li.active{
    background-color: white;
}
.extension_Language div ul li.active a{
    color:#38618B !important;
    font-weight: bold;
}
.extension_Language > div:hover ul{
    display: block;
    position: absolute;
} 
.extension_Language .languageModule{
    background-color: white !important;
    border-radius: 0px !important;
}
/*-----  End of LANGUAGE  ------*/


/*===============================
=            CONTENT            =
===============================*/
#content {
    margin-bottom:20px;
    padding-top: 30px;
}
#content #content-inter {
    border-radius: 4px;
    padding: 10px;
}
#content #content-inter .row {
    margin-left: 0;
}
.cycle-pager {
    margin: 15px 0 0 0;
    text-align: center;
}
.cycle-pager span {
    background: url('img/pager.png') scroll left bottom no-repeat transparent;
    cursor: pointer;
    display: inline-block;
    height: 11px;
    margin: 0 5px;
    width: 11px;
}
.cycle-pager span.cycle-pager-active,
.cycle-pager span:hover {
    background-position: left top;
}
.cycle-slideshow .slide {
    margin: 0;
}
.tooltip{
    white-space:normal;
}
.tooltip-inner {
    max-width: 500px;
}
.extension_Menu ul li a{
    padding: 5px 15px;
}
.extension_Menu ul li{
    margin-left: 5px;
    line-height: 20px;
    margin-right: 5px;
}

#menu .container{
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-bottom: 15px;
}

/*====================================
=            ImageModule             =
====================================*/
.extension_Image img{
    width:100%;
}

    /*====================================
    =            FamilyModule            =
    ====================================*/
    
    .teaser{
        font-size: 10px;
        padding-bottom: 4px;
    }

    .extension_Familly .teaser:nth-of-type(1n+2){
        margin-top: 20px;
    }
    .extension_Familly .product .infos-container{
        width: 84%;
        float: right;
        clear:none;
        padding-left: 20px;
    }
    .extension_Familly .product .image-container{
        width: 16%;
        float: left;
        clear: none;
    }
    .extension_Familly .product img{
        width: 12.5%;
    }
    
    
    .product .description .short
    {
        font-size: 11px;
        line-height: 20px;
        font-family: Arial,Helvetica,sans-serif;
    }
    .extension_Familly .product img{
        width: auto;
        height: auto;
        border:1px solid #DFDFDF;
        border-radius:50%;
        display: inline-block;
    }
    .extension_Familly .product{
        padding: 0px;
    }

    .family-title{
        color: black;
        font-weight: 300;
        font-family: "Open Sans",Arial,Helvetica,sans-serif;
        text-rendering: optimizelegibility;
        text-transform: uppercase;
        font-size: 25px;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
        
    .product .btn.btn-primary{
        display: inline-block;
        background-color: #3266CC;
        font-family: "Open Sans";
        font-size: 14px;
        color: #FFF;
        text-transform: uppercase;
        margin: 15px 0px 0px;
        padding: 8px 30px;
        white-space: nowrap;
        position: absolute;
        top: 0px;
        height: 40px;
        border-radius: 5px !important;
        text-decoration: none;
        box-sizing: border-box;
        text-align: center;
        transition: background-color 200ms linear 0s;
        
    }
    .extension_Familly .product-title{
        padding-bottom: 0px;
        line-height: 40px;
        margin-bottom: 0px;
    }
    /*-----  End of FamilyModule  ------*/

    /*=====================================
    =            ProductModule            =
    =====================================*/
    .extension_Products .products .product.odd{
        background-color: #F9F9F9;
    }
    .extension_Products .products .product{
        margin-bottom: 15px;
        border: 1px solid #CECECE;
        padding: 0;
        box-shadow: 0px 2px 0px #ECECEC;
        position: relative;
    }   
    .extension_Products .products .product .description{
        font-size: 11px;
        line-height: 16px;
        color: #888;
    }
    .extension_Products .product h1{
        color: #1F356F;
        font-weight: bold;
        font-size: 15px;
    }
    .extension_Products .product h1 {
        margin-bottom: 0px;
        padding-bottom : 0;
    }
    .extension_Products .products .product .category{
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 11px;
        float: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow:hidden;
        line-height: 30px;
        
        min-height: 0px;
    }
    .extension_Products .products .product .qty{
        text-align: left;
        float:right;
    }
    .extension_Products .products .product .header-quantity{
        text-align: left;
        
    }
    .extension_Products .products .product .price,
    .extension_Products .products .product .header-price{
        text-align: right;
    }
    .extension_Products .families > h1 {
        text-transform: uppercase;
        font-size: 25px;
        font-weight: 300;
        font-family: "Open Sans",Arial,Helvetica,sans-serif;
    }
    .extension_Products .products .product .select2-container.select2{
        width: 60px !important;
        text-align: left;
    }
    .extension_Products .products .product .row{
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .extension_Products .extension_Products {
        margin-top: 20px;
    }
    .extension_Products .products .product .price span{
        line-height: 30px;
    }
    .extension_Products .products .product .qty .select2-choice span{
        line-height: 20px;
    }
    .extension_Products h1{
        color: #333;
    }
    /*-----  End of ProductModule  ------*/
    
    

    /*LOGIN*/
    .nav{
        margin-bottom: 0px;
    }
    .nav > li.dropdown:hover .dropdown-menu,
    .nav > li.dropdown.open:hover .dropdown-menu,
    .nav > li.dropdown.open .dropdown-menu  {
        display: block !important;
    }

    .user .dropdown-menu li a:hover{
        background-color: #F6F6F6 !important;
        background-image: linear-gradient(to bottom, #F6F6F6, #F6F6F6);
        background-repeat: repeat-x;
        color: #38618B ;
        font-weight: bold;
    }
    .logged-infos .nav > li > a {
        color:#38618B;
    }
    .user .dropdown-menu{
        margin-top: -1px;
        border-radius: 0px;
        padding:0px;
        display: none;
        width: inherit;
    }
    .logged-infos, .login-menu{
        padding-top: 15px;
    }
    .logged-infos .nav > li > a:hover, .logged-infos .nav > li > a:focus {
        background-color: #FE9500;
        color: white;
        transition:0.7s all;
    }
    .logged-infos .nav > li > a{
        font-weight: bold;
        text-transform: uppercase;
        min-width: 150px;
        padding:2px 8px;
        border: 1px solid #ddd;
        border-radius: 5px;
        text-align: center;
    }
    .logged-infos .gravatar-user-wrapper{
        display: inline;
        text-align: left;
    }
    /*END LOGIn*/


    .fa.fa-warning{
        color: #FE9500;
        font-size: 20px;
    }
    /*SPECIAL INFORMATION WARNNG*/
    .display-information-warning{
        clear: both;
        display: block;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding:10px;
        background-color: #F8F5D2;
        border: 1px solid #D3D871;
        color: #635C56;
        float: left;
        font-size: 13px;
        border-radius: 5px !important;
        margin: 15px 0px 25px;
    }
    /*END OF SPECIAL INFORMATION WARNING*/

@media (max-width: 767px) {
    body {
        padding-left: 0px;
        padding-right: 0px;
    }
    #header, #menu {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    #content {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
    .cycle-slideshow .slide {
        margin-bottom: 15px;
        margin-top: 15px;
    }
    .cycle-slideshow .slide.row-fluid [class*="span"] {
        float: left;
        margin-left: 2.12766%;
        width: 31.9149%;
    }
    .cycle-slideshow .slide.row-fluid [class*="span"]:first-child {
        margin-left: 0;
    }
    #header ul.list-menu {
        background-color: #FFF;
        border-bottom: 1px solid #AAA;
        box-shadow: 2px 2px 10px #CCC;
        left: 0;
        top: 60px;
        width: 100%;
}
    #header ul.list-menu li a {
        font-size: 12px;
    }
    #header ul.list-menu li.active a,
    #header ul.list-menu li a:hover {
        background-color: #38618B;
        color: #FFF;
    }
}

@media (max-width: 480px) {
    #header ul li a {
        font-size: 11px;
        letter-spacing: -0.11px;
        min-width: 70px;
    }
    h1 {
        font-size: 26px;
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 20px;
    }
    h1, h2, h3 {
        line-height: 28px;
    }
    .cycle-slideshow .slide.row-fluid [class*="span"] {
        float: none;
        margin: 15px 0;
        width: 100%;
    }
    #menu .language,
    #menu .logged-infos {
        clear: both;
        float: left;
        margin: 5px 0;
    }
    #menu nav ul > li {
        clear: both;
        margin: 5px 0;
    }
    #menu nav ul {
        margin: 0;
    }
}
/*-----  End of CONTENT  ------*/

/*=============================================
=            SPECIAL QUESTION PAGE            =
=============================================*/

body.question-step-vip .extension_Tunnel .pull-right ,
body.question-ticket-menu .extension_Tunnel .pull-right{
    display: none;
}

@media (min-width: 991px){
    body.question-step-vip .extension_Question,
    body.question-ticket-menu .extension_Question{
        margin-top: -70px;
    }    
}
@media (max-width: 991px){
    body.question-step-vip .extension_Text:nth-child(2),
    body.question-ticket-menu .extension_Text:nth-child(2){
        margin-top: 16px;
        
    }   
}
body.question-step-vip .extension_Text:nth-child(2),
body.question-ticket-menu .extension_Text:nth-child(2){
    /*margin-top: 16px;*/
    background-color: red;
    background-color: #F8F5D2;
    border: 1px solid #D3D871;
    color: #635C56;
    border-radius: 5px;
    padding:20px;
}
body.question-step-vip .extension_Text h4,
body.question-ticket-menu .extension_Text h4{
    font-size: 25px;
}

/*-----  End of SPECIAL QUESTION PAGE  ------*/
/*==================================
=            Formulaire            =
==================================*/
.extension_Form label{
    color: #333;
    font-family: "Open Sans";
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}


.extension_Form input[type="text"],
 .extension_Form input[type="password"]{
    height:35px;
}
/*-----  End of Formulaire  ------*/




/*=====================================
=            STYLES MANUEL            =
=====================================*/

#account1fr h2,
#account2fr h2,
#account1en h2,
#account2en h2,
#account1it h2,
#account2it h2 {
    font-size: 22px;
    line-height: 1;
    padding-bottom: 20px;
    text-transform: uppercase;
}
#account1fr h3,
#account2fr h3,
#account1en h3,
#account2en h3,
#account1it h3,
#account2it h3 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
}
#tnlfamilfr h2:first-of-type,
#ticketprfr h2:first-of-type,
#vipprodafr h2:first-of-type,
#menusprofr h2:first-of-type,
#tnlfamilen h2:first-of-type,
#ticketpren h2:first-of-type,
#vipprodaen h2:first-of-type,
#menusproen h2:first-of-type,
#tnlfamilit h2:first-of-type,
#ticketprit h2:first-of-type,
#vipprodait h2:first-of-type,
#menusproit h2:first-of-type {
    margin-top: 0;
}
#tnlfamilfr h2,
#ticketprfr h2,
#vipprodafr h2,
#menusprofr h2,
#tnlfamilen h2,
#ticketpren h2,
#vipprodaen h2,
#menusproen h2,
#tnlfamilit h2,
#ticketprit h2,
#vipprodait h2,
#menusproit h2 {
    line-height: 1;
    margin-bottom: 20px;
    margin-top: 50px;
}
#tnllabelfr h1,
#tnllabelen h1,
#tnllabelit h1 {
    color: #333;
    font-family: 'Open Sans',Arial,Helvetica,sans-serif;
    font-size: 31.5px;
    line-height: 40px;
    padding-bottom: 0;
    text-transform: none;
}
#sliderlafr,
#sliderlaen,
#sliderlait {
    margin-bottom: -15px;
}
#ticketcafr,
#ticketcaen,
#ticketcait {
    margin-top: 45px;
}
#vipcartafr,
#vipcartaen,
#vipcartait {
    margin-top: 96px;
}
#menuscarfr,
#menuscaren,
#menuscarit {
    margin-top: 96px;
}
#textite2fr a,
#textite2en a,
#textite2it a,
#textite1fr a,
#textite1en a,
#textite1it a,
#textite4fr a,
#textite4en a,
#textite4it a{
    background-color: #FF7F00;
    border-radius: 5px; 
    color: #FFFFFF; 
    display: block; 
    font-family: Open Sans; 
    font-size: 14px;
    line-height: 23px; 
    margin: 0 5px; 
    padding: 10px 30px; 
    text-align: center; 
    text-transform: uppercase; 
    width: 300px;
}
#textite2fr a:hover,
#textite2en a:hover,
#textite2it a:hover,
#textite1fr a:hover,
#textite1en a:hover,
#textite1it a:hover,
#textite4fr a:hover,
#textite4en a:hover,
#textite4it a:hover {
    background-color: #FD8E21;
    text-decoration: none;
}
#textite2fr i,
#textite2en i,
#textite2it i,
#textite1fr i,
#textite1en i,
#textite1it i,
#textite4fr i,
#textite4en i,
#textite4it i {
    font-size: 20px;
    line-height: 23px;
    padding-right: 10px;
}
#prchoicvfr .ticket-info,
#prchoicven .ticket-info,
#prchoicvit .ticket-info,
#prchoicmfr .ticket-info,
#prchoicmen .ticket-info,
#prchoicmit .ticket-info {
    margin-bottom: 0;
    margin-top: 0;
    padding: 7px 15px 15px 15px;
}

@media (max-width: 767px) {
    #menuscarfr,
    #menuscaren,
    #menuscarit,
    #ticketcafr,
    #ticketcaen,
    #ticketcait,
    #vipcartafr,
    #vipcartaen,
    #vipcartait {
        margin-top: 30px;
    }
}
/*-----  End of STYLES MANUEL  ------*/



/*======================================
=            FOOTER CONTACT            =
======================================*/
.footerContact{
    background-color: white;
    border: 1px solid #CCC;
    box-shadow: 0px 2px 0px #ECECEC;
    margin:15px 20px 15px 20px;
    padding-bottom: 35px;
}
.footerContact ul{
    margin:0 !important;
    margin-bottom: 15px;
    padding-left: 15px;
}
.footerContact h2{
    padding:5px 0px;
    background-color: #F9F9F9;    
    font-size: 18px;
    margin-bottom: 15px;
    padding-left: 15px;
}
.footerContact ul li a{
    color:#1F356F;
    text-decoration: none;
}
.footerContact ul li:last-of-type{
    border-right: none;
}
.footerContact ul li{
    border-right: 1px solid #D9D9D9;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    list-style-type: none;
    margin: 0;
    margin-right: 25px;padding: 0;
    padding:5px 0;
    padding-right: 25px;
}
.footerContact ul li:nth-child(1) a{
    font-size: 22px;
    font-weight: normal;
    position: relative;
}
.footerContact ul li:nth-child(1) a:after{
    color: #888;
    content:"0.34€/min";
    font-family: Arial,Helvetica,sans-serif;
    font-size: 11px;
    position: absolute;
    right: 1px;
    top:22px;
}
.footerContact ul li:nth-child(1) a{
    color:black;
}

/* multi langual footer */
.lang-fr-FR .lang-en.footerContact, 
.lang-fr-FR .lang-it.footerContact {
    display: none !important;
}

.lang-it-IT .lang-en.footerContact, 
.lang-it-IT .lang-fr.footerContact {
    display: none !important;
}

.lang-en-GB .lang-fr.footerContact, 
.lang-en-GB .lang-it.footerContact {
    display: none !important;
}

@media (max-width: 767px) {
    .footerContact{
        padding-bottom: 5px;
    }
    .footerContact ul li{
        border:none;
        display: block;
        margin-bottom: 5px;
    }

}
/*-----  End of FOOTER CONTACT  ------*/


/*=======================================
=            RESPONSIVE MENU            =
=======================================*/

.container a.btn-toggle-menu{
    color:white;
    font-size: 40px;
    line-height: 50px;
}
.container a.btn-toggle-menu:hover{
    cursor: pointer;
}

/*-----  End of RESPONSIVE MENU  ------*/

/*=========================================
=            GLOBAL RESPONSIVE            =
=========================================*/

@media (max-width: 767px) {

    article.extension_Image div{
        margin: auto;
        text-align: center;
    }
    .extension_Familly .product .btn{
        
        position: relative !important;
        width: auto;
        float: right;
    }
   .extension_Products .products .product .qty{
        text-align: left;
    }
    .extension_Products .products .product .header-quantity{
        text-align: left;
    }
    .extension_Products .products .product .price,
    .extension_Products .products .product .header-price{
        text-align: left;
    }
    .extension_Products .products .product .select2-container.select2{
        width: 100% !important;
    }
    .allMembers-wrapper.vertical.no-picture .bottom-button.create.only{
        right: 0px;
        position: relative;
    }

}
@media (min-width: 480px) and (max-width: 767px){
    .extension_Familly .product-title{
        width: 70%;
    }
    .extension_Familly .product .description,
    {
        width: 70%;
        display: inline-block;
        
    }  
}
@media (min-width: 767px) {
    .extension_Familly .product-title{
        width: 60%;
    }
    .extension_Familly .product .description,
    {
        width: 60%;
        display: inline-block;
        
    }   
}
@media (min-width: 480px){
    .extension_Familly .product img{
        width: 16%;
    }
    
}
@media (max-width: 480px) {
    #marineland #content.container-fluid{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .extension_Familly .product .infos-container{
        clear: both;
        width:100%;
        padding-left: 0px;
    }
    
    .extension_Familly .product img{
        margin-top: 15px;
        width: 100%;
        text-align: center;
        margin-left: auto;margin-right: auto;
        float: none;
    
    }
    .extension_Familly .product .btn{
        
        float: none !important;
    }
    .extension_Familly .product .image-container{
        margin-bottom: 35px;
    }
}
@media (max-width: 400px) {
    .familyModule{
        padding-top: 20px;
    }
    #content{
        margin-left:0px !important; 
        margin-right:0px !important;    
    } 
    .extension_Familly .product .infos-container{
        
        padding: 20px;
    }
    .btn-ext.btn-buy-now,
    .btn-holder {
        text-align: center !important;
        float: none;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px !important;
    }
    .extension_Familly h2,
    .teaser
    {
        text-align: center;
    }
    .row-fluid [class*="span"]{
        padding: 0px !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px){
  .allMembers-wrapper.vertical.no-picture .bottom-button.create.only{
        right: 259px;
    }
}
@media (min-width: 950px) {
    .extension_Familly .product-title{
        width: 65%;
        
    }
    .extension_Familly .product .description,
    
    {
        width: 60%;
        display: inline-block;
        
    }   
}
@media (min-width: 1200px) {
    .container{
        width: 970px !important;
    }
    .allMembers-wrapper.vertical.no-picture .bottom-button.create.only{
        right: 244px;
    }
}
/*-----  End of GLOBAL RESPONSIVE  ------*/

/*CART MODULE*/

body.step-vip .extension_Cart, 
body.votre-menu .extension_Cart,
body.Billeterie .extension_Cart {
    margin-top: 64px;
}
body.selection-date .extension_Cart,
.extension_Booking{
    margin-top: 34px;
}
.extension_Booking .header .row{
    padding-top:0px; 
    padding-bottom:0px; 
}
body.produits .extension_Cart{
    margin-top: 44px;
} 

.extension_Cart .productLabelWidth{
    position: relative;
}
.extension_Cart .cart-summary.hotelItems tbody tr{
    border-bottom:1px solid #eee;
}
.extension_Cart .cart-summary.hotelItems tbody tr.total-row{
    border-bottom: none;
}
.extension_Cart.col-md-12 .cart-summary .total{
    width: 35%;
    padding-top: 0px !important;
}
.extension_Cart.col-md-12 .cart-summary .products tr.odd,
.extension_Cart.col-md-12 .cart-summary .products tr.even
{
    padding: 15px;
    height: 45px;
    border : 1px solid #BFBFBF;
}
.extension_Cart.col-md-12 .cart-summary .products tr.odd .productLabelWidth,
.extension_Cart.col-md-12 .cart-summary .products tr.even .productLabelWidth
{
    padding-left: 10px;
}
.extension_Cart.col-md-12 .cart-summary .products tr .img-wrapper{
    border: 1px solid #ddd;
}
.extension_Cart.col-md-12 .cart-summary .products tr .img-wrapper i{
    font-size: 30px;
    color: #ddd;
    padding: 8px;
}
.extension_Cart.col-md-12 .cart-summary .total .totalValue .right{
    text-align: right;
    font-size:20px;
}
.extension_Cart.col-md-12 .cart-summary .total .totalValue .right strong{
    padding-right: 18px;
}
/* checkboxes color */
.extension_Cart .promo-insurance .checker span{
    color:white;
}
.extension_Cart .promo-insurance .checker span.checked{
    color:black;
}
@media (max-width: 767px) {
    body.produits .extension_Cart, 
    body.step-vip .extension_Cart, 
    body.votre-menu .extension_Cart,
    body.Billeterie .extension_Cart {
        margin-top: 0px;
        padding-top: 0px;
    }
}
.extension_Cart.col-md-12 .moreInfos{
    display: block;
}
/*END CART MODULE*/

#history1fr h2,
#history1en h2,
#history1it h2,
#history2fr h2,
#history2en h2,
#history2it h2 {
    margin-bottom: 20px;
}
.extension_Tunnel .step.complete .number{
    background-color: #84B001 !important;
    border:1px solid #84B001 !important;

}
.tunnel-btn.btn-next.pull-left:hover{
    text-decoration: none;
}


.tunnel{
    border: 1px solid #D0D0D0 !important;
    box-shadow: 0px 2px 0px #F4F4F4;
    padding: 20px;
    background-color: #F9F9F9 !important;
    overflow: auto;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    padding-top: 20px !important;
}
.tunnel-like {
    background-color: #F9F9F9;
    border: 1px solid #D0D0D0;
    box-shadow: 0 2px 0 #F4F4F4;
    margin-top: 40px;
    overflow: auto;
    padding: 20px;
}

.textite5fr span {
    margin: 0 35px;
}

.btn-ext {
    background-color: #3266cc;
    border: none;
    border-radius: 5px !important;
    color: #FFF;
    cursor: pointer;
    font-family: 'Open Sans';
    margin-top: 15px;
    padding: 10px 30px;
    text-transform: uppercase;
}

.btn-ext:hover {
    background-color: #0246cb;
    color: #FFF;
    text-decoration: none;
}


#error {
    background-color: #fbe5e5;
    border: 1px solid #d30000;
    color: #d30000;
    margin: 20px 20px 30px;
    padding: 30px;
}



.extension_Adhesion .adhesionUserName,
.extension_Adhesion b,
.extension_Adhesion .adhLibelle,
.extension_Adhesion .manageMembership-wrapper .manageMembership-subWrapper .manageHeader,
.adhesion-infos.no-pass > span:first-child  
{
    color: #2A3D69 !important;
}
.extension_Adhesion .nextAdh{
    background-color: #F5FAFF;
    padding:12px 10px !important;
    border: none !important;
}
.extension_Adhesion .adhLibelle{
    padding-top: 0px !important;
}
.extension_Adhesion .allMembers-wrapper.vertical .user-infos .memberTooltip{
    background-color: #BD120E;
}

.extension_Adhesion .createUser-wrapper .fileupload-holder{
    display: inline-block !important;
}
.extension_Adhesion .allMembers-wrapper.vertical .adhesion-wrapper .modifUser-wrapper .bottom-button{
    border :none !important;
    line-height:34px; 
}

.extension_Products .products .product .header-price,
.extension_Products .products .product .price {
    display:none;
}


@media (min-width: 768px) {
    .adhesion-wrapper.porteur .userEmail{
        display: block;
    }
}
@media (min-width: 480px) and (max-width: 980px){
    .extension_Adhesion .adhesionUserName span{
        display: inline-block !important;
        margin-left: 5px;
    }
}

@media (min-width: 768px) and (max-width: 991px){
    .memberTooltip{

    }
    .allMembers-wrapper.vertical .adhesion-wrapper .user-infos .bottom-button{
        width: 120px !important;
    }
}

@media (max-width: 573px){
    
    .extension_Adhesion.col-md-12 .allMembers-wrapper.vertical .adhesion-wrapper .user-infos .bottom-button{
        
    }
    .extension_Adhesion.col-md-12 .user-infos.col-xs-8{
        width: 100% !important;
    }
}

.extension_Adhesion.col-md-6 .user-infos{
    margin-left: 0px !important;
}


a.btn-hotel{
    padding: 10px 20px;
    text-decoration: none;
    color: #FFF;
    border-radius: 5px;
    transition: 0.3s all;
    float: left;
    clear: both;
    margin-top: 15px;
}
a.btn-hotel:hover{
    transition: 0.3s all;
    opacity:0.7;
}

a.btn-hotel.orange{
    background-color: #FF7F00;
}
a.btn-hotel.green{
    background-color: #57BD42;
}
a.btn-hotel.blue{
    background-color: #3266CC;
}


.additionnalNextWrapper {
    position :relative;
    width: 278px;
    float: right;
    background: #F8F5D2;
    padding: 10px;
}

.additionnalNextWrapper span {
    display : block;
    float : right;
     text-transform : uppercase;
     font-weight : bold;
}

.additionnalNextWrapper a {
    position : absolute;
    right : 15px;
    bottom : -42px;
    display : inline-block;
    background : #FE9500;
    color : #fff;
    text-transform : uppercase;
}

.additionnalNextWrapper a:hover {
    opacity : 0.8;
    color : #fff;
    
}

/*c'est pas moi, j'étais à la piscine*/
.lang-fr-FR .en,
.lang-fr-FR .it,
.lang-it-IT .fr,
.lang-it-IT .en,
.lang-en-EN .fr,
.lang-en-GB .fr,
.lang-en-EN .it,
.lang-en-GB .it {
    display: none;
}

.view.default .product select {
    float : none;
}

.session .session-hour .ticket {
    position : relative;
}

.lang-fr-FR .extension_Sessions .ticket:after {
    content: "Selectionner";
    display: block;
    position: static;
    top: 5px;
    right: 0;
    background: #FF7F00;
    color: #fff;
    padding: 5px 10px;
    border-radius: 8px;
}

.lang-fr-EN .extension_Sessions .ticket:after {
    content: "Select";
}

.lang-fr-IT .extension_Sessions .ticket:after {
    content: "selezionare";
}

.cart-summary .savings {
    color : green !important;
}


.extension_Products .products .product .price {
    display : inline-block !important;
}


.product .price {
    width: 80%;
    float: left;
} 
.product .qtty {
    width: 20%;
    float: left;
}

.anchorlinks a {
    display: inline-block;
    padding: 0 15px;
    border-right: 1px solid #d9d9d9;
        color: #1f356f;
    text-decoration: none;
        font-size: 16px;
    font-weight: bold;
        padding: 5px 25px;
}

.anchorlinks a:first-child {
    padding-left : 0;
}

.anchorlinks a:last-child {
    border-right : none;
}

/* paragraphe avec required pour les recommandations de saisie mdp par ViJ */
.extension_Form p.required {
    font-size: 13px;
    margin: 0 0 5px;
}

/* Modification pour les recommendation de MDP */
.extension_Form p.required {
    font-size: 12px;
    margin: 0 0 5px;
}

/* 11/09/2017 Modification Lit Bébé : BaR */
.litBebe {
    display: none !important;
}

/* 12/04/2018 Modification bouton suivant : CaM */
.btn.btn-tunnel-next {
    background: none;
    background-color: #ff7f00;
    border: medium none;
    border-radius: 5px !important;
    color: white;
    cursor: pointer;
    font-family: 'Open Sans', Arial, sans-serif;
    margin-top: 15px;
    padding: 10px 30px;
    text-shadow: none;
    box-shadow: none;
    margin-left: 15px;
}

//View Vertical align: center entre la Qty, Description
.extension_Products .vertical .productLine{
    flex-basis:100%;
    display:flex;
    align-items:center;
}
.extension_Products .vertical .productLine .category{
    flex-basis:80%;
}
.extension_Products .vertical .productLine .price{
    flex-basis:0%;
}
.extension_Products .vertical .productLine .qty{
    flex-basis:18%;
}